import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import {  SafeHtmlPipe }   from './shared/pipes/safe-html.pipe';
import {  FolderFilterPipe }   from './shared/pipes/folder-filter.pipe';
import {  MyFilterPipe }   from './shared/pipes/my-filter.pipe';
import {TruncatePipe} from './app.pipe';

import {  FilterUniqueNamePipe }   from './shared/pipes/unique-name.pipe';
import {  FilterUniqueQuestionPipe }   from './shared/pipes/unique-question.pipe';
//import { ReversePipe } from './reverse.pipe';
import {  ReversePipe }   from './shared/pipes/reverse-filter.pipe';
import { OrderByPipe } from './shared/pipes/order-by.pipe';
import {  SafeHtmlPipe }   from './shared/pipes/safe-html.pipe';
import {  SearchFilterPipe }   from './shared/pipes/search-filter.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
	FolderFilterPipe,
	TruncatePipe,
	MyFilterPipe,
	FilterUniqueNamePipe,
	FilterUniqueQuestionPipe,
	ReversePipe,
	OrderByPipe,
	SafeHtmlPipe,
	SearchFilterPipe
  ],
  imports: [
    CommonModule,
	NgSelectModule,
	ReactiveFormsModule,
	FormsModule,
	MatTooltipModule
  ],
  exports: [
	FolderFilterPipe,
	TruncatePipe,
	MyFilterPipe,
	FilterUniqueNamePipe,
	FilterUniqueQuestionPipe,
	ReversePipe,
	OrderByPipe,
	SafeHtmlPipe,
	SearchFilterPipe,
	NgSelectModule,
	ReactiveFormsModule,
	FormsModule,
	MatTooltipModule
	]
})
export class SharedPipesModule { }
